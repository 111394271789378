import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BaseRoutes } from '@nts/std';
import { environment } from 'src/environments/environment';

const title = environment ? environment.appTitle : 'Application Title';
const routes: Routes = [

  // #region rotte con autenticazione
  ...BaseRoutes.getBaseAuthentictedRoutes(
    environment,
    [
      ...BaseRoutes.getEntityRoutes(
        title,
        'zoom',
        () => import('./zoom/zoom.module').then(m => m.ZoomModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'zoomuserlist',
        () => import('./zoom-user-list/zoom-user-list.module').then(m => m.ZoomUserListModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'userzoomlist',
        () => import('./user-zoom-list/user-zoom-list.module').then(m => m.UserZoomListModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'roleoftenantzoomlist',
        () => import('./role-of-tenant-zoom-list/role-of-tenant-zoom-list.module').then(m => m.RoleOfTenantZoomListModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'zoomroleoftenantlist',
        () => import('./zoom-role-of-tenant-list/zoom-role-of-tenant-list.module').then(m => m.ZoomRoleOfTenantListModule)
      ),
      ...BaseRoutes.getDefaultRoutes(
        title,
        environment,
        true
      )
    ]
  ),
  // #endregion rotte con autenticazione

  // rotte base per la gestione degli errori
  ...BaseRoutes.getBaseErrorsRoute()
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
